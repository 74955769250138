import React, { useEffect, useRef } from "react";
import { Layout } from "../../containers";
import { GetInTouch } from "../../components";
import { Container } from "./styled";
import styles from "../../styles/Home.module.scss";

function About({ setLanguage, language, jsonData }) {
  const observer = useRef(null);

  useEffect(() => {
    observer.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("fade-active");
        }
      });
    });

    document.querySelectorAll(".box").forEach((box) => {
      observer.current.observe(box);
    });

    document.querySelectorAll(".side-box-reversed").forEach((box) => {
      observer.current.observe(box);
    });
    document.querySelectorAll(".side-box").forEach((box) => {
      observer.current.observe(box);
    });
    document.querySelectorAll(".half-box").forEach((box) => {
      observer.current.observe(box);
    });
    return () => {
      observer.current.disconnect();
    };
  }, []);

  return (
    <Layout setLanguage={setLanguage} jsonData={jsonData} language={language}>
      <Container>
        <div className="box hero-section">
          <div className="hero-section-content">
            <h1 className={styles.dynamicTitle}>
              {language === "por" ? "Sobre nós" : "Sobre nosotros"}
            </h1>
          </div>
        </div>
        <div className="container-with-title">
          <div className="header">
            <div className="img-container">
              <img
                src="/assets/about-icon.svg"
                alt="Information icon"
                style={{ borderRadius: 0, top: "-90%" }}
              />
              <h2>{jsonData?.about_section_first_title}</h2>
            </div>
          </div>
          <div className="side-box container-with-title-content">
            <img src="/assets/Contact-first.jpg" alt="" />
            <div>
              <p>{jsonData?.about_section_first_content}</p>
            </div>
          </div>
        </div>
        <div className="container-with-title">
          <div className="header reversed">
            <div className="img-container">
              <img src="/assets/euskady-icon.svg" alt="Information icon" />
              <h2>{jsonData?.about_section_second_title}</h2>
            </div>
          </div>
          <div className="side-box-reversed container-with-title-content reversed">
            <img src="/assets/Contact-second.jpg" alt="" />
            <div>
              <p>{jsonData?.about_section_second_content}</p>
              <p
                className="theme-link"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {jsonData?.theme_page_book_button}{" "}
                <a
                  className="wpp"
                  aria-label="Chat on WhatsApp"
                  href="https://wa.me/542302466274"
                  style={{
                    width: "200px",
                    height: "auto",
                    border: "0",
                    marginLeft: "1rem",
                  }}
                >
                  <img
                    style={{ width: "200px", height: "auto", border: "0" }}
                    alt="Chat on WhatsApp"
                    src="/assets/WhatsAppButtonGreenLarge.svg"
                  />
                </a>
              </p>
            </div>
          </div>
        </div>
        <GetInTouch jsonData={jsonData} />
      </Container>
    </Layout>
  );
}

export default About;
