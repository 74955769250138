import styled from "styled-components";

export const Container = styled.div`
  min-height: 100vh;
  width: 100vw;
  max-width: 1920px;
  background-color: #d6cfb9;
  .hero-section {
    background: url("/assets/hero-d.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
    height: 35vh;
    .hero-section-content {
      width: 90%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: auto;
      h1 {
        margin-top: 0;
        color: #000;
      }
      p {
        color: #2f5241;
        margin: 0;
        font-size: clamp(18px, 4vw, 30px);
      }
    }
  }
  .container-with-title {
    width: 90%;
    margin: 2rem auto;
    padding: 0 0 2rem 0;
    .header {
      display: flex;
      align-items: center;
      padding: 4rem 0 0 0;
      &:after {
        content: "";
        display: inline-block;
        width: 75%;
        height: 100%;
        margin-left: 4%;
        border-bottom: 2px solid #2f5241;
      }
      &.reversed {
        flex-direction: row-reverse;
        &:after {
          margin-right: 4%;
        }
      }
      .img-container {
        width: fit-content;
        position: relative;
        img {
          border-radius: 24px;
          width: 50px;
          top: -40px;
          left: 0;
          position: absolute;
        }
      }
    }
    .container-with-title-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      img {
        width: 100%;
      }
    }
  }
  @media screen and (min-width: 867px) {
    .hero-section {
      height: 80vh;
    }
    .container-with-title {
      padding: 3rem 0;
      .container-with-title-content {
        flex-direction: row;
        margin: 5rem auto;
        &.reversed {
          flex-direction: row-reverse;
        }
        img {
          width: 40%;
          transition: transform 0.2s;
          :hover {
            transform: scale(1.1);
          }
        }
        div {
          width: 50%;
        }
      }
    }
  }
`;
