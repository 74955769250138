import React, { useEffect, useRef } from "react";
import { Layout } from "../../containers";
import { GetInTouch } from "../../components";
import { Container } from "./styled";
import styles from "../../styles/Home.module.scss";
import { useHistory, Link } from "react-router-dom";

function Home({ setLanguage, language, jsonData }) {
  let path = window.location.hash;

  const observer = useRef(null);

  useEffect(() => {
    observer.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("fade-active");
        }
      });
    });

    document.querySelectorAll(".box").forEach((box) => {
      observer.current.observe(box);
    });

    document.querySelectorAll(".side-box-contact").forEach((box) => {
      observer.current.observe(box);
    });
    document.querySelectorAll(".side-box-reversed").forEach((box) => {
      observer.current.observe(box);
    });
    document.querySelectorAll(".side-box").forEach((box) => {
      observer.current.observe(box);
    });
    document.querySelectorAll(".half-box").forEach((box) => {
      observer.current.observe(box);
    });
    return () => {
      observer.current.disconnect();
    };
  }, []);

  const history = useHistory();

  useEffect(() => {
    if (window.location.hash === "#contactForm") {
      window.scrollTo({
        top: document.documentElement.scrollHeight - window.innerHeight,
        behavior: "smooth",
      });
    }
  }, [history, path]);

  return (
    <Layout setLanguage={setLanguage} jsonData={jsonData} language={language}>
      <Container>
        <div className="box hero-section">
          <div className="hero-section-content">
            <p>{jsonData?.home_hero_subtitle}</p>
            <h1 className={styles.dynamicTitle}>
              {jsonData?.home_hero_title_first_line}
              <br />
              {jsonData?.home_hero_title_second_line}
            </h1>
            <a className="btn-primary" href="#contactForm">
              {jsonData?.theme_page_book_button_small}
            </a>
          </div>
        </div>
        <div className="box rooms-section">
          <div className="img-container">
            <div className="header">
              <div className="img-container">
                <img src="/assets/rooms-icon.svg" alt="Information icon" />
                <h2>{jsonData?.home_rooms_section_title}</h2>
              </div>
            </div>
          </div>
          <div className="rooms-content">
            <div className="rooms-content-left">
              <p>{jsonData?.home_rooms_section_description}</p>
              <div className="room ejecutivas">
                <Link
                  to="/rooms/Ejecutiva"
                  onClick={() => {
                    window.scroll(0, 0);
                  }}
                >
                  <img
                    className="toScaleImage"
                    src="/assets/room Ejecutiva.jpg"
                    alt="Habitaciones ejecutivas"
                  />
                </Link>
                <p>{jsonData?.home_rooms_section_room_ejecutivas}</p>
              </div>
              <div className="room donostia">
                <Link
                  to="/rooms/Donostia"
                  onClick={() => {
                    window.scroll(0, 0);
                  }}
                >
                  <img
                    className="toScaleImage"
                    src="/assets/room Suite Donostia.jpg"
                    alt="Habitaciones Donostia"
                  />
                </Link>
                <p>{jsonData?.home_rooms_section_room_donostia}</p>
              </div>
            </div>
            <div className="rooms-content-right">
              <div className="room standart">
                <Link
                  to="/rooms/Standard"
                  onClick={() => {
                    window.scroll(0, 0);
                  }}
                >
                  <img
                    className="toScaleImage"
                    src="/assets/room standart.jpg"
                    alt="Habitaciones Standart"
                  />
                </Link>
                <p>{jsonData?.home_rooms_section_room_standart}</p>
              </div>
              <div className="room guernica">
                <Link
                  to="/rooms/Guernica"
                  onClick={() => {
                    window.scroll(0, 0);
                  }}
                >
                  <img
                    className="toScaleImage"
                    src="/assets/room Guernica.jpg"
                    alt="Habitaciones Guernica"
                  />
                </Link>
                <p>{jsonData?.home_rooms_section_room_guernica}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="box servicis-section">
          <div className="service-item">
            <div className="service-item-extra">
              <img src="/assets/wifi-icon.png" alt="Wifi icon" />
              <p>{jsonData?.home_servicies_first}</p>
            </div>
          </div>
          <div className="service-item">
            <div className="service-item-extra">
              <img src="/assets/buffet-icon.png" alt="Buffet icon" />
              <p>{jsonData?.home_servicies_second}</p>
            </div>
          </div>
          <div className="service-item">
            <div className="service-item-extra">
              <img src="/assets/tv-icon.svg" alt="TV icon" />
              <p>{jsonData?.home_servicies_third}</p>
            </div>
          </div>
          <div className="service-item">
            <div className="service-item-extra">
              <img
                src="/assets/air-conditioning-icon.svg"
                alt="Air-conditioning icon"
              />
              <p>{jsonData?.home_servicies_fith}</p>
            </div>
          </div>
        </div>
        <div className="buffet-section-container">
          <div className="header reversed">
            <div className="img-container">
              <img
                className=""
                src="/assets/buffet-icon.png"
                alt="Information icon"
              />
              <h2>{jsonData?.home_buffet_section_title}</h2>
            </div>
          </div>
          <div className="side-box buffet-section">
            <img src="/assets/Home-buffet.jpg" alt="" />
            <div>
              <p>{jsonData?.home_buffet_section_content}</p>
              <p
                className="theme-link"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {jsonData?.theme_page_book_button}{" "}
                <a
                  className="wpp"
                  aria-label="Chat on WhatsApp"
                  href="https://wa.me/542302466274"
                  style={{
                    width: "200px",
                    height: "auto",
                    border: "0",
                    marginLeft: "1rem",
                  }}
                >
                  <img
                    style={{ width: "200px", height: "auto", border: "0" }}
                    alt="Chat on WhatsApp"
                    src="/assets/WhatsAppButtonGreenLarge.svg"
                  />
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="location-section-container">
          <div className="header">
            <div className="img-container">
              <img
                className=""
                src="/assets/location-icon.svg"
                alt="Information icon"
              />
              <h2>{jsonData?.home_location_section_title}</h2>
            </div>
          </div>
          <div className="side-box-reversed location-section reversed">
            <img src="/assets/Home-entrance.jpg" alt="" />
            <div>
              <p>{jsonData?.home_location_section_content}</p>
              <p
                className="theme-link"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {jsonData?.theme_page_book_button}{" "}
                <a
                  className="wpp"
                  aria-label="Chat on WhatsApp"
                  href="https://wa.me/542302466274"
                  style={{
                    width: "200px",
                    height: "auto",
                    border: "0",
                    marginLeft: "1rem",
                  }}
                >
                  <img
                    style={{ width: "200px", height: "auto", border: "0" }}
                    alt="Chat on WhatsApp"
                    src="/assets/WhatsAppButtonGreenLarge.svg"
                  />
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="experience-section-container">
          <div className="header reversed">
            <div className="img-container">
              <img src="/assets/experience-icon.svg" alt="Information icon" />
              <h2>
                {jsonData?.home_experience_title_first}
                <br />
                {jsonData?.home_experience_title_second}
              </h2>
            </div>
          </div>
          <div className="side-box experience-section">
            <img src="/assets/image-hotel.png" alt="Euskadi Hotel" />
            <div>
              <p>{jsonData?.home_experience_text}</p>
              <p
                className="theme-link"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {jsonData?.theme_page_book_button}{" "}
                <a
                  className="wpp"
                  aria-label="Chat on WhatsApp"
                  href="https://wa.me/542302466274"
                  style={{
                    width: "200px",
                    height: "auto",
                    border: "0",
                    marginLeft: "1rem",
                  }}
                >
                  <img
                    style={{ width: "200px", height: "auto", border: "0" }}
                    alt="Chat on WhatsApp"
                    src="/assets/WhatsAppButtonGreenLarge.svg"
                  />
                </a>
              </p>
            </div>
          </div>
        </div>
        <GetInTouch jsonData={jsonData} />
      </Container>
    </Layout>
  );
}

export default Home;
