import styled from "styled-components";

export const Container = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: auto;
  height: 10vh;
  width: calc(100vw - 10%);
  max-width: 1920px;
  background-color: transparent;
  padding: 0 5%;
  position: fixed;
  top: 0;
  z-index: 10;
  transition: background-color 0.5s ease;
  &.isNotTop {
    background-color: #fff;
    transition: background-color 0.5s ease;
    .servicis {
      color: #000;
      transition: color 0.5s ease;
    }
  }
  .hamburguer-menu-icon {
    display: flex;
    width: 30px;
    height: 30px;
  }

  .navigation-container {
    display: none;
    flex-direction: column;
    list-style: none;
    width: fit-content;
    align-items: flex-end;
    justify-content: flex-start;
    width: 150px;
    padding: 0 1rem;
    position: absolute;
    top: 7vh;
    background: #fff;
    right: 0;
    height: 85vh;
    z-index: 10;
    box-shadow: 0 0 10px rgb(0 0 0 / 0.2);
    &.active {
      display: flex;
    }
    ul {
      display: flex;
      flex-direction: column;
      list-style: none;
      width: fit-content;
      align-items: center;
      justify-content: space-between;
      width: fit-content;
      padding: 0;
      margin: 0;
      text-align: right;
      height: 25vh;
      &:hover > .servicis-submenu {
        display: block;
      }
      li {
        list-style: none;
        width: fit-content;
        padding: 0;
        font-family: "Poppins";
        margin: 0;
        padding: 20px;
        cursor: pointer;
        &:hover {
          font-size: 18px;
        }
        &.account-menu {
          color: #8c909e;
        }
        a {
          color: #000;
          width: 100%;
          font-weight: 400;
          font-family: "Poppins";
          text-decoration: none;
          text-transform: uppercase;
          font-size: 16px;
          font-weight: 700;
        }
      }
      .servicis {
        color: #000;
        font-weight: 700;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 16px;
        cursor: pointer;
        position: relative;
        padding: 20px;
        .servicis-submenu {
          display: none;
          top: 50px;
          left: -60%;
          width: 100px;
          height: fit-content;
          padding: 0.5rem;
          position: absolute;
          background: #fff;
          box-shadow: 0 0 10px rgb(0 0 0 / 0.2);
          z-index: 12;
          li {
            width: calc(100% - 1rem);
            border-bottom: 1px solid #ccc;
            padding: 0.5rem 0;
            text-align: left;
            &:hover {
              background: #ccc;
            }
            a {
              font-size: 12px;
              font-weight: 700;
            }
          }
        }
      }
      .servicis:hover > .servicis-submenu {
        visibility: visible;
        opacity: 1;
      }
      .servicis:hover > .servicis-submenu {
        display: block;
      }
      #logoutButton {
        .visible {
          display: block;
        }
        &.hidden {
          display: none;
        }
      }
    }
  }
  .logo-languagge-container {
    width: 25%;
    display: flex;
    align-items: center;
    .logo-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;
      margin-right: 1rem;
      a {
        width: 70%;
        img {
          width: 100%;
        }
      }
      h4 {
        width: 70%;
        font-family: "Roboto", sans-serif;
        display: none;
      }
    }
  }
  ul {
    display: flex;
    list-style: none;
    width: fit-content;
    align-items: center;
    justify-content: space-between;
    width: fit-content;
    padding: 0;
    li {
      list-style: none;
      width: fit-content;
      padding: 0 1rem 0 0;
      font-weight: 700;
      a {
        color: #000;
        width: 100%;
        font-family: "Poppins";
        text-decoration: none;
        text-transform: uppercase;
        font-size: 12px;
      }
      &:last-of-type {
        padding: 0.7rem 0;
        a {
          padding: 0.7rem 0;
        }
      }
    }
    .servicis {
      color: #000;
      font-family: "Poppins";
      text-decoration: none;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 12px;
      cursor: pointer;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      transition: color 0.5s ease;
      svg {
        height: 20px;
        margin: 1rem 0;
      }
      .languagge-icon {
        .cls-1,
        .cls-2 {
          fill: none;
          stroke: #fff;
          stroke-miterlimit: 10;
        }
        .cls-1 {
          stroke-width: 1.55px;
        }
        .cls-2 {
          stroke-width: 2.1px;
        }
      }
      .menu-icon {
        .cls-1 {
          fill: none;
          stroke: #fff;
          stroke-linecap: round;
          stroke-miterlimit: 10;
          stroke-width: 2.73px;
        }
      }
      .location-icon {
        .cls-1 {
          fill: #fff;
          fill-rule: evenodd;
        }
      }
      .servicis-submenu {
        display: block;
        top: 100%;
        left: 0;
        width: 100px;
        padding: 0.5rem;
        position: absolute;
        background: #f5f5f5;
        color: #000;
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s, opacity 0.5s ease;
        li {
          width: fit-content;
          &:hover {
            background: #ccc;
          }
          a {
            font-size: 12px;
          }
        }
      }
    }
    .servicis .visible {
      visibility: visible;
      opacity: 1;
    }
    #logoutButton {
      .visible {
        display: block;
      }
      &.hidden {
        display: none;
      }
    }
  }
  img {
    width: 40%;
  }
  @media (min-width: 867px) {
    padding: 0 5%;
    width: calc(100vw - 10%);
    .logo-languagge-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 20%;
      .logo-container {
        width: 70%;
        a {
          width: auto;
          img {
            width: 100%;
          }
        }
        h4 {
          width: auto;
          padding-left: 1rem;
          display: block;
          justify-content: center;
        }
      }
    }
    .hamburguer-menu-icon {
      display: none;
    }
    .navigation-container {
      width: 60%;
      display: flex;
      position: unset;
      top: 0;
      height: fit-content;
      box-shadow: none;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      background: transparent;
      ul {
        width: 100%;
        flex-direction: row;
        li {
          padding: 0 1rem 0 0;
          font-size: 16px;
          a {
            font-size: 16px;
          }
        }
        .servicis {
          font-size: 16px;
          .servicis-submenu {
            left: 10%;
          }
        }
      }
    }
    ul {
      li a {
        font-size: 16px;
      }
      .servicis {
        font-size: 16px;
        svg {
          height: 30px;
        }
      }
    }
    ul .servicis .servicis-submenu {
      width: 150px;
      top: 100%;
      li a {
        font-size: 14px;
      }
    }
  }
  @media (min-width: 1440px) {
    .navigation-container {
      width: 45%;
    }
  }
`;
