import styled from "styled-components";

export const Container = styled.div`
  min-height: 100vh;
  width: 100vw;
  max-width: 1920px;
  background-color: #fff;
  margin-top: calc(10vh + 3rem);
  .hero-section {
    width: 100%;
    margin: 1rem auto;
    h1 {
      width: 100%;
      margin-bottom: 3rem;
      margin: 0 5% 3rem;
    }
    .slider-container {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      .image-gallery {
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: fit-content;
        #prev,
        #next {
          width: 40px;
          height: 40px;
          transform: rotate(90deg);
          border: none;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0;
          max-height: unset;
          border-radius: 50%;
          svg {
            width: 90%;
          }
          &:hover {
            border: none;
            background: #2f5241;
          }
        }
        #next {
          transform: rotate(270deg);
        }
      }
      .image-gallery-container-extra {
        overflow: hidden;
        width: 100%;
      }
      .image-gallery-container {
        display: flex;
        width: 100%;
        height: 100%;
        transition: transform 0.5s ease 0s;
        img {
          width: 100%;
          height: auto;
          margin: auto;
          cursor: pointer;
        }
      }
    }
  }
  .room-information {
    width: 90%;
    margin: 2rem auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    div {
      width: 45%;
      border: 1px solid black;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 1rem 0;
      img {
        height: 30px;
        width: auto;
      }
    }
  }
  .container-with-title {
    width: 90%;
    margin: 2rem auto;
    padding-top: 4rem;
    &.rooms-recomendation {
      background-color: #d6cfb9;
      width: 90%;
      padding: 5%;
      margin-bottom: 0;
      .header {
        padding-top: 4rem;
      }
      .container-with-title-content {
        width: 100%;
        margin: 3rem 0;
        flex-wrap: wrap;
        flex-direction: row !important;
      }
    }
    .header {
      display: flex;
      align-items: center;
      &:after {
        content: "";
        display: inline-block;
        width: 75%;
        height: 100%;
        margin-left: 4%;
        border-bottom: 2px solid #2f5241;
      }
      &.reversed {
        flex-direction: row-reverse;
        &:after {
          margin-right: 4%;
        }
      }
      .img-container {
        width: fit-content;
        position: relative;
        img {
          width: 50px;
          top: -40px;
          left: 0;
          position: absolute;
        }
      }
    }
    .container-with-title-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &.reversed {
        flex-direction: row-reverse;
      }
      .right-container {
        width: 100%;
        ul {
          list-style: none;
          padding: 0;
          font-weight: 700;
          font-size: 20px;
        }
      }
      .sidebox-container {
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        width: 100%;
        .sidebox {
          background: #f5f7f8;
          padding: 1rem;
          width: 100%;
          div {
            width: 100%;
            justify-content: flex-start;
            img {
              width: 50px;
              margin-right: 2rem;
            }
          }
        }
      }
      .rooom-card {
        background: #fff;
        width: 45%;
        padding-bottom: 1rem;
        border-radius: 24px;
        overflow: hidden;
        margin-bottom: 1rem;
        &.hiden {
          display: none;
        }
        img {
          width: 100%;
          max-height: 300px;
        }
        .room-content {
          width: 90%;
          margin: 0 auto;
          display: flex;
          flex-direction: column;
          h3 {
            margin-bottom: 0;
          }
          .subtitle {
            color: rgb(47, 82, 65);
          }
          hr {
            width: 100%;
            border: 1px solid rgb(47, 82, 65);
          }
          .icon-container {
            width: 90%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            img {
              width: 15%;
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 867px) {
    .hero-section {
      width: 90%;
      h1 {
        margin: 0 0 3rem 0;
      }
      .slider-container {
        width: 100%;
        #prev,
        #next {
          width: 65px;
          height: 65px;
        }
        .image-gallery-container-extra {
          width: 100%;
          height: 85vh;
          border-radius: 24px;
          overflow: hidden;
        }
        .image-gallery-container {
          width: 100%;
          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
    .container-with-title {
      padding: 3rem 0;
      .container-with-title-content {
        flex-direction: row;
        margin: 5rem auto;
        width: 70% !important;
        .right-container {
          width: 45%;
        }
        .sidebox-container {
          width: 25%;
        }
        &.reversed {
          flex-direction: row-reverse;
        }
        img {
          width: 40%;
          transition: transform 0.2s;
        }
        div {
          width: 50%;
        }
      }
    }
    .room-information div {
      width: 24%;
    }
    .container-with-title .container-with-title-content {
      &.room-content {
        width: 100% !important;
      }
      .rooom-card {
        width: 30%;
      }
    }
  }
`;
