import styled from "styled-components";

export const BackgroundContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.7;
  position: absolute;
  z-index: 30;
`;
export const Container = styled.div`
  display: flex;
  z-index: 50;
  position: absolute;
  height: 30vh;
  width: 80vw;
  max-width: 900px;
  max-height: 500px;
  margin-top: 35vh;
  left: 10vw;
  padding: 1% 2%;
  background-color: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 45px;
  @media only screen and (min-width: 867px) {
    height: 30vh;
    width: 30vw;
    left: 35vw;
    top: 0;
  }
`;
