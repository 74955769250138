import React, { useEffect, useRef, useState } from "react";
import { Container } from "./styled";
import styles from "../../styles/Home.module.scss";

const GetInTouch = ({ jsonData }) => {
  return (
    <Container>
      <div className="flex_center_space-between main-container">
        <div className="">
          <div className="contact-container wpp">
            <a aria-label="Chat on WhatsApp" href="https://wa.me/542302466274">
              <img
                alt="Chat on WhatsApp"
                src="/assets/WhatsAppButtonGreenLarge.svg"
              />
            </a>
          </div>
          <div className="contact-container">
            <img
              className="phone-icon"
              src="/assets/phone icon.svg"
              alt="Phone icon"
            />
            <p>(02302) 46-6274</p>
          </div>
          <div className="contact-container">
            <img
              className="mail-icon"
              src="/assets/mail icon.svg"
              alt="mail icon"
            />
            <p>reservas.hoteleuskadi@gmail.com</p>
          </div>
        </div>
        <div className="">
          <h3 className={styles.dynamicTitleh3}>
            {jsonData?.get_in_touch_where_we_are}
          </h3>
          <p>
            Calle: 22 Nº 776
            <br />
            General Pico - La Pampa <br />
            Argentina
          </p>
          <a href="https://maps.app.goo.gl/TJ2wmLaCvsoYvKb96" target="_blank">
            {jsonData?.get_in_touch_open_maps}
          </a>
        </div>
      </div>
    </Container>
  );
};

export default GetInTouch;
