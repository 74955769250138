import React from "react";
import { Container, BackgroundContainer } from "./styled";

const Loader = () => {
  return (
    <>
      <Container id="SuccessPopUp">
        <h1>Cargando...</h1>
      </Container>
      <BackgroundContainer />
    </>
  );
};

export default Loader;
