import styled from "styled-components";

export const Container = styled.div`
  min-height: 100vh;
  width: 100vw;
  max-width: 1920px;
  background-color: #d6cfb9;
  .hero-section {
    background: url("/assets/hero-d.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
    height: 35vh;
    .hero-section-content {
      width: 90%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: auto;
      h1 {
        margin-top: 0;
        color: #000;
      }
      p {
        color: #2f5241;
        margin: 0;
        font-size: clamp(18px, 4vw, 30px);
      }
    }
  }
  .container-with-title {
    width: 90%;
    margin: 2rem auto 0;
    padding: 0 0 0 2rem;
    .header {
      display: flex;
      align-items: center;
      padding: 4rem 0 0 0;
      &:after {
        content: "";
        display: inline-block;
        width: 75%;
        height: 100%;
        margin-left: 4%;
        border-bottom: 2px solid #2f5241;
      }
      &.reversed {
        flex-direction: row-reverse;
        &:after {
          margin-right: 4%;
        }
      }
      .img-container {
        width: fit-content;
        position: relative;
        img {
          width: 50px;
          top: -40px;
          left: 0;
          position: absolute;
        }
      }
    }
    .container-with-title-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
  .contact-section {
    display: flex;
    flex-direction: column-reverse;
    width: 90%;
    margin: 0 auto;
    padding: 0;
    .contact-info-container {
      width: 100%;
      a {
        width: 100px;
        img {
          margin: 1rem 0;
          width: 25%;
        }
      }
    }
    .contact-form-container {
      width: 100%;
      form {
        display: flex;
        flex-flow: column;
        input {
          padding: 1rem;
          margin: 1rem 0;
          border-radius: 14px;
          border: 1px solid #000;
          background: transparent;
          &.message {
            height: 60px;
          }
        }
        .personal-data {
          display: flex;
          justify-content: space-between;
          input {
            width: 40%;
          }
        }
        button {
          border-radius: 0;
          max-height: 100px;
          height: 50px;
        }
      }
    }
  }
  @media screen and (min-width: 867px) {
    .hero-section {
      height: 80vh;
    }
    .container-with-title {
      padding: 3rem 0;
      .container-with-title-content {
        flex-direction: row;
        margin: 0 auto;
        &.reversed {
          flex-direction: row-reverse;
        }
        img {
          width: 40%;
          transition: transform 0.2s;
          :hover {
            transform: scale(1.1);
          }
        }
      }
    }
    .contact-section {
      flex-direction: row;
      .contact-info-container {
        width: 90%;
      }
    }
  }
`;
