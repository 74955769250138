import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 90%;
  max-width: 1920px;
  height: auto;
  margin: 0 auto;
  padding: 0 5% 5rem;
  h2 {
    margin-bottom: 0;
  }
  .main-container {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    a {
      color: rgb(47, 82, 65);
      font-weight: 700;
    }
  }
  .contact-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem 0;
    background: #f3f3f3;
    border-radius: 24px;
    padding: 0 0.5rem;
    &.wpp {
      background: none;
      a {
        width: 100%;
        img {
          width: 100%;
        }
      }
    }
    .phone-icon,
    .mail-icon {
      width: 30px;
      padding-right: 1rem;
    }
  }
  @media (min-width: 867px) {
    .main-container {
      width: 55%;
      flex-direction: row;
      align-items: center;
    }
    .contact-container {
      width: 100%;
      margin: 1rem auto;
      align-items: space-around;
    }
  }
`;
