import styled from "styled-components";

export const Container = styled.div`
  min-height: 100vh;
  width: 100vw;
  max-width: 1920px;
  background-color: #d6cfb9;
  .hero-section {
    background: url("/assets/hero-d.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
    height: 40vh;
    .hero-section-content {
      width: 90%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: auto;
      margin-top: 3rem;
      h1 {
        margin-top: 0;
        color: #000;
      }
      p {
        color: #2f5241;
        margin: 0;
        font-size: clamp(18px, 4vw, 30px);
      }
    }
  }
  .rooms-section {
    width: 90%;
    margin: 4rem auto;
    .header {
      h2 {
        &:after {
          content: "";
          display: inline-block;
          width: 75%;
          height: 100%;
          margin-left: 4%;
          border-bottom: 2px solid #2f5241;
        }
      }
      .img-container {
        width: 100%;
        img {
          width: 50px;
        }
      }
    }
    .rooms-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      .rooms-content-left {
        width: 100%;
        p {
          width: 50%;
        }
        .room {
          display: flex;
          flex-direction: column;
          img {
            cursor: pointer;
            border-radius: 24px;
            width: 100%;
          }
          p {
            font-weight: 700;
            text-align: center;
            width: 100%;
          }
          &.ejecutivas {
            width: 100%;
          }
          &.donostia {
            width: 100%;
            margin: 1rem auto;
          }
        }
      }
      .rooms-content-right {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
          width: 50%;
        }
        .room {
          display: flex;
          flex-direction: column;
          img {
            border-radius: 24px;
            width: 100%;
            cursor: pointer;
          }
          p {
            font-weight: 700;
            text-align: center;
            width: 100%;
          }
          &.standart {
            width: 100%;
          }
          &.guernica {
            width: 100%;
            margin: 1rem auto;
          }
        }
      }
    }
  }
  .servicis-section {
    background: #f5f7f8;
    display: flex;
    padding: 1rem;
    justify-content: center;
    width: 90%;
    margin: 4rem auto;
    flex-wrap: wrap;
    .service-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 2rem;
      width: 30%;
      .service-item-extra {
        display: flex;
        padding: 1.5rem;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 80%;
        height: 100%;
        border: 1px solid #2f5241;
        border-radius: 50%;
        font-weight: 700;
        text-align: center;
        img {
          height: 30px;
        }
        p {
          font-size: 12px;
        }
      }
    }
  }

  .buffet-section-container,
  .experience-section-container,
  .location-section-container {
    width: 90%;
    margin: 2rem auto;
    .header {
      display: flex;
      align-items: center;
      padding: 4rem 0 0 0;
      &:after {
        content: "";
        display: inline-block;
        width: 75%;
        height: 100%;
        margin-left: 4%;
        border-bottom: 2px solid #2f5241;
      }
      &.reversed {
        flex-direction: row-reverse;
        &:after {
          margin-right: 4%;
        }
      }
      .img-container {
        width: fit-content;
        position: relative;
        img {
          width: 50px;
          top: -40px;
          left: 0;
          position: absolute;
        }
      }
    }
  }
  .location-section-container {
    .img-container {
      img {
        height: 50px;
      }
    }
  }
  .buffet-section,
  .experience-section,
  .location-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .contact-section {
    display: flex;
    flex-direction: column-reverse;
    width: 90%;
    margin: 0 auto;
    padding: 3rem 0;
    .contact-info-container {
      width: 100%;
      a {
        width: 100px;
        img {
          margin: 1rem 0;
          width: 25%;
        }
      }
    }
    .contact-form-container {
      width: 100%;
      form {
        display: flex;
        flex-flow: column;
        input {
          padding: 1rem;
          margin: 1rem 0;
          border-radius: 14px;
          border: 1px solid #000;
          background: transparent;
          &.message {
            height: 60px;
          }
        }
        .personal-data {
          display: flex;
          justify-content: space-between;
          input {
            width: 40%;
          }
        }
        button {
          border-radius: 0;
          max-height: 100px;
          height: 50px;
        }
      }
    }
  }
  @media screen and (min-width: 867px) {
    .hero-section {
      height: 80vh;
    }
    .rooms-section .rooms-content {
      flex-direction: row;
      .rooms-content-left {
        width: 50%;
        .room {
          &.donostia {
            width: 50%;
          }
        }
      }

      .rooms-content-right {
        width: 50%;
        .room {
          &.standart {
            width: 80%;
            margin-bottom: 20%;
          }
          &.guernica {
            width: 60%;
          }
        }
      }
    }
    .servicis-section {
      .service-item {
        width: 20%;
        height: auto;
        .service-item-extra {
          width: 40%;
          p {
            font-size: 14px;
          }
        }
      }
    }

    .buffet-section-container,
    .experience-section-container,
    .location-section-container {
      .buffet-section,
      .experience-section,
      .location-section {
        flex-direction: row;
        margin: 5rem auto;
        &.reversed {
          flex-direction: row-reverse;
        }
        img {
          width: 40%;
          transition: transform 0.2s;
          :hover {
            transform: scale(1.1);
          }
        }
        div {
          width: 50%;
        }
      }
    }
    .contact-section
      .contact-info-container
      .contact-form-container
      form
      input.message {
      height: 120px;
    }
  }
  @media screen and (min-width: 1000px) {
    .hero-section {
      background-size: cover;
      background-repeat: no-repeat;
    }
    .services-section {
      flex-direction: row;
      margin-top: -5%;
      .services-item {
        padding: 1rem;
      }
    }
    .home-options-container {
      flex-direction: row;
      .image-container {
        width: 50%;
      }
      .about-container {
        width: 50%;
        p {
          width: 70%;
        }
      }
    }
    .cta-section {
      padding: 3rem;
    }
    .contact-section {
      flex-direction: row;
      .contact-info-container {
        width: 50%;
      }
      .contact-form-container {
        width: 50%;
        form input.message {
          height: 110px;
        }
      }
    }
  }
`;
