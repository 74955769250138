import React, { useState, useEffect, useRef } from "react";
import { Container } from "./styled";
import { Link, useHistory } from "react-router-dom";

const Navbar = ({ setLanguage, isNavbarBlack, jsonData, language }) => {
  const [clientWindowHeight, setClientWindowHeight] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);
  const [isLanguagueMenuOpen, setIsLanguagueMenuOpen] = useState(false);
  const history = useHistory();

  //HANDLE SCROLL FOR HEADER
  const handleScroll = () => {
    setClientWindowHeight(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Container
      className={`finish-button-nav ${
        clientWindowHeight >= 15 || isNavbarBlack === true ? "isNotTop" : null
      }`}
      isNavbarBlack={isNavbarBlack}
    >
      <div className="logo-languagge-container">
        <div
          className="logo-container"
          onClick={() => {
            history.push("/");
            window.scroll(0, 0);
          }}
        >
          <Link to="/">
            {windowWidth >= 867 ? (
              <img src="/assets/logo.png" alt="Hotel Esukadi logo" />
            ) : (
              <img src="/assets/iso-logo.png" alt="Hotel Esukadi logo" />
            )}
          </Link>
        </div>
        <ul>
          <li
            className="servicis"
            onMouseEnter={() => setIsLanguagueMenuOpen(true)}
            onMouseLeave={() => setIsLanguagueMenuOpen(false)}
          >
            {language}
            <ul
              id="servicis-submenu"
              className={`servicis-submenu ${
                isLanguagueMenuOpen ? "visible" : ""
              }`}
            >
              <li
                onClick={(e) => {
                  setIsLanguagueMenuOpen(false);
                  setLanguage("por");
                }}
              >
                POR
              </li>
              <li
                onClick={(e) => {
                  setIsLanguagueMenuOpen(false);
                  setLanguage("esp");
                }}
              >
                ESP
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <img
        className="hamburguer-menu-icon"
        alt="menu icon"
        src="/assets/Menu-icon-b.png"
        onClick={(e) => setIsHamburgerMenuOpen(!isHamburgerMenuOpen)}
      />
      <div
        className={`navigation-container ${
          isHamburgerMenuOpen ? "active" : null
        }`}
      >
        <ul>
          <li className="servicis">
            {jsonData?.navbar_rooms_link}
            <ul id="servicis-submenu" className="servicis-submenu">
              <li>
                <Link
                  to="/rooms/Standard"
                  onClick={() => {
                    window.scroll(0, 0);
                    setIsHamburgerMenuOpen(false);
                  }}
                >
                  Standard
                </Link>
              </li>
              <li>
                <Link
                  to="/rooms/Ejecutiva"
                  onClick={() => {
                    window.scroll(0, 0);
                    setIsHamburgerMenuOpen(false);
                  }}
                >
                  Ejecutiva
                </Link>
              </li>
              <li>
                <Link
                  to="/rooms/Donostia"
                  onClick={() => {
                    window.scroll(0, 0);
                    setIsHamburgerMenuOpen(false);
                  }}
                >
                  Donostia
                </Link>
              </li>
              <li>
                <Link
                  to="/rooms/Guernica"
                  onClick={() => {
                    window.scroll(0, 0);
                    setIsHamburgerMenuOpen(false);
                  }}
                >
                  Guernica
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <Link
              to="/contact"
              onClick={() => {
                window.scroll(0, 0);
              }}
            >
              {jsonData?.navbar_contact_link}
            </Link>
          </li>
          <li>
            <Link
              onClick={() => {
                window.scroll(0, 0);
              }}
              to="/about"
            >
              {jsonData?.navbar_us_link}
            </Link>
          </li>
          <li>
            <Link className="btn-primary" to="/#contactForm">
              {jsonData?.theme_page_book_button_small}
            </Link>
          </li>
        </ul>
      </div>
    </Container>
  );
};
export default Navbar;
