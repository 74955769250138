import styled from "styled-components";
export const Container = styled.div`
  min-height: 100vh;
  margin-top: 10vh;
  width: 100vw;
  max-width: 1920px;
  background-color: #d6cfb9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 5%;
  img {
    width: 80%;
  }
  div {
    width: 90%;
    margin: 2rem auto;
  }
  @media screen and (min-width: 867px) {
    flex-direction: row;
    justify-content: center;
    img {
      width: 30%;
      margin-right: 5%;
    }
  }
`;
