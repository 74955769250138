import React, { useEffect, useRef } from "react";
import { Layout } from "..";
import { Container } from "./styled";
import { Link } from "react-router-dom";
import styles from "../../styles/Home.module.scss";

function ErrorPage({ setLanguage, language, jsonData }) {
  const observer = useRef(null);

  useEffect(() => {
    observer.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("fade-active");
        }
      });
    });

    document.querySelectorAll(".box").forEach((box) => {
      observer.current.observe(box);
    });

    document.querySelectorAll(".side-box-reversed").forEach((box) => {
      observer.current.observe(box);
    });
    document.querySelectorAll(".side-box").forEach((box) => {
      observer.current.observe(box);
    });
    document.querySelectorAll(".half-box").forEach((box) => {
      observer.current.observe(box);
    });
    return () => {
      observer.current.disconnect();
    };
  }, []);

  return (
    <Layout
      setLanguage={setLanguage}
      jsonData={jsonData}
      isNavbarBlack={true}
      language={language}
    >
      <Container>
        <img src="/assets/404.png" alt="error icon" />
        <div>
          <h1 className={styles.dynamicTitle}>404</h1>
          <h2 className={styles.dynamicTitleh2}>{jsonData?.error_title}</h2>
          <p>{jsonData?.error_content}</p>
          <Link to="/" className="btn-primary">
            {language === "por" ? "Vá para começar" : "Ir al inicio"}
          </Link>
        </div>
      </Container>
    </Layout>
  );
}

export default ErrorPage;
