import React, { useEffect, useRef } from "react";
import { Layout } from "../../containers";
import { GetInTouch } from "../../components";
import { Container } from "./styled";
import styles from "../../styles/Home.module.scss";

function Contact({ setLanguage, language, jsonData }) {
  const observer = useRef(null);

  useEffect(() => {
    observer.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("fade-active");
        }
      });
    });

    document.querySelectorAll(".box").forEach((box) => {
      observer.current.observe(box);
    });

    document.querySelectorAll(".side-box-contact").forEach((box) => {
      observer.current.observe(box);
    });
    document.querySelectorAll(".side-box").forEach((box) => {
      observer.current.observe(box);
    });
    document.querySelectorAll(".half-box").forEach((box) => {
      observer.current.observe(box);
    });
    return () => {
      observer.current.disconnect();
    };
  }, []);

  return (
    <Layout setLanguage={setLanguage} jsonData={jsonData} language={language}>
      <Container>
        <div className="box hero-section">
          <div className="hero-section-content">
            <h1 className={styles.dynamicTitle}>
              {language === "por" ? "Contato" : "Contacto"}
            </h1>
          </div>
        </div>
        <div className="container-with-title">
          <div className="header">
            <div className="img-container">
              <img src="/assets/reservas-icon.svg" alt="Information icon" />
              <h2>{jsonData?.contact_section_title}</h2>
            </div>
          </div>
          <div className="side-box-contact container-with-title-content contact-section"></div>
        </div>
        <GetInTouch jsonData={jsonData} />
      </Container>
    </Layout>
  );
}

export default Contact;
