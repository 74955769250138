import React, { useEffect, useRef, useState } from "react";
import { Layout } from "../../containers";
import { GetInTouch } from "../../components";
import { Container } from "./styled";
import { Link } from "react-router-dom";

function Rooms({ setLanguage, language, jsonData }) {
  const [activeRoom, setActiveRoom] = useState(null);
  const [windowDimensions, setWindowDimensions] = useState(0);
  const [roomImages, setRoomImages] = useState(null);

  let slug = window.location.pathname.replace("/rooms/", "");

  const importAll = (r) => {
    let newValue = r.keys();
    return newValue;
  };

  useEffect(() => {
    let listOfImagesTosave = importAll(
      require.context("../../../public/assets", false, /\.(png|jpe?g|svg)$/)
    ).map((image) => image.replace(".", ""));
    const filteredArray = listOfImagesTosave.filter((str) =>
      str.startsWith(`/gallery-room-${activeRoom}`)
    );

    setRoomImages(filteredArray);
  }, [activeRoom]);

  const observer = useRef(null);

  useEffect(() => {
    setActiveRoom(slug);
  }, [slug]);

  useEffect(() => {
    observer.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("fade-active");
        }
      });
    });

    document.querySelectorAll(".box").forEach((box) => {
      observer.current.observe(box);
    });

    document.querySelectorAll(".side-box-contact").forEach((box) => {
      observer.current.observe(box);
    });
    document.querySelectorAll(".side-box").forEach((box) => {
      observer.current.observe(box);
    });
    document.querySelectorAll(".half-box").forEach((box) => {
      observer.current.observe(box);
    });
    return () => {
      observer.current.disconnect();
    };
  }, []);

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("load", handleResize);
    setWindowDimensions(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const changeGalleryPosition = (target) => {
    let galeryContainer = document.getElementById(`image-gallery-container`);
    let galeryContainerExtra = document.getElementsByClassName(
      `image-gallery-container-extra`
    )[0];
    let valueToSlide = galeryContainerExtra.offsetWidth;
    const computedStyle = window.getComputedStyle(galeryContainer);
    const transformValue = computedStyle.transform;
    let actualValue =
      transformValue.split(" ")[4] === undefined
        ? 0
        : Number(transformValue.split(",")[4]);

    if (
      target === "next" &&
      actualValue > (galeryContainer.childElementCount - 1) * -valueToSlide
    )
      galeryContainer.style.transform = `translate(${
        actualValue - valueToSlide
      }px, ${0}px)`;
    else if (target === "prev" && actualValue < 0)
      galeryContainer.style.transform = `translate(${
        actualValue + valueToSlide
      }px, ${0}px)`;
    else if (
      target === "next" &&
      actualValue === (galeryContainer.childElementCount - 1) * -valueToSlide
    )
      galeryContainer.style.transform = `translate(${0}px, ${0}px)`;
    else if (target === "prev" && actualValue === 0)
      galeryContainer.style.transform = `translate(${
        (galeryContainer.childElementCount - 1) * -valueToSlide
      }px, ${0}px)`;
  };

  return (
    <Layout
      setLanguage={setLanguage}
      jsonData={jsonData}
      positionUnset={true}
      language={language}
    >
      <Container>
        <div className="hero-section">
          <h1>
            {activeRoom !== null && activeRoom === "Standard"
              ? `${language === "por" ? "Quarto" : "Habitaciones"} Standard`
              : activeRoom === "Ejecutiva"
              ? `${language === "por" ? "Quarto" : "Habitación"} Ejecutiva`
              : activeRoom === "Guernica"
              ? "Suite Guernica"
              : activeRoom === "Donostia"
              ? "Suite Donostia"
              : null}
          </h1>
          <div className="slider-container">
            <div className="image-gallery">
              <button
                id="prev"
                className="btn-primary ghost"
                onClick={(e) => changeGalleryPosition("prev")}
              >
                <svg
                  aria-hidden="true"
                  focusable="false"
                  role="presentation"
                  class="icon icon-caret"
                  viewBox="0 0 10 6"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9.354.646a.5.5 0 00-.708 0L5 4.293 1.354.646a.5.5 0 00-.708.708l4 4a.5.5 0 00.708 0l4-4a.5.5 0 000-.708z"
                    fill="currentColor"
                  ></path>
                </svg>
              </button>
              <div className="image-gallery-container-extra">
                <div
                  id={`image-gallery-container`}
                  className="image-gallery-container"
                >
                  {roomImages &&
                    roomImages.length > 0 &&
                    roomImages.map(function (item, index, array) {
                      return (
                        <img
                          alt="Room gallery"
                          key={`img-${index}`}
                          className="inventory-header_img"
                          src={`/assets${roomImages[index]}`}
                        />
                      );
                    })}
                </div>
              </div>
              <button
                id="next"
                className="btn-primary ghost"
                onClick={(e) => changeGalleryPosition("next")}
              >
                <svg
                  aria-hidden="true"
                  focusable="false"
                  role="presentation"
                  class="icon icon-caret"
                  viewBox="0 0 10 6"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9.354.646a.5.5 0 00-.708 0L5 4.293 1.354.646a.5.5 0 00-.708.708l4 4a.5.5 0 00.708 0l4-4a.5.5 0 000-.708z"
                    fill="currentColor"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div className="room-information">
          <div>
            <img src="/assets/people-icon.svg" alt="Information icon" />
            <p>
              {jsonData?.rooms_people}
              {activeRoom === "Donostia"
                ? "4"
                : activeRoom === "Ejecutiva"
                ? "3"
                : "2"}
            </p>
          </div>
          <div>
            <img src="/assets/calendar-icon.svg" alt="Information icon" />
            <p>{jsonData?.rooms_min_reservation}</p>
          </div>
          <div>
            {activeRoom === "Donostia" ? (
              <img src="/assets/bed-three-icon.svg" alt="Information icon" />
            ) : (
              <img src="/assets/bed-icon.svg" alt="Information icon" />
            )}
            <p>
              {activeRoom === "Donostia"
                ? jsonData.rooms_beds_donostia
                : jsonData?.rooms_beds}
            </p>
          </div>
          <div>
            <img src="/assets/room-size-icon.svg" alt="Information icon" />
            <p>
              {activeRoom === "Guernica"
                ? "50m"
                : activeRoom === "Donostia"
                ? "40m"
                : activeRoom === "Ejecutiva"
                ? "28"
                : "28m"}
              <sup>2</sup>
            </p>
          </div>
        </div>
        <div className="container-with-title">
          <div className="header">
            <div className="img-container">
              <img src="/assets/description-icon.svg" alt="Information icon" />
              <h2>{jsonData?.rooms_description_title}</h2>
            </div>
          </div>
          <div className="side-box-contact container-with-title-content room-content">
            <div className="right-container">
              <p>
                {activeRoom === "Ejecutiva"
                  ? jsonData?.rooms_description_text_ejecutiva
                  : activeRoom === "Donostia"
                  ? jsonData?.rooms_description_text_donostia
                  : activeRoom === "Standard"
                  ? jsonData?.rooms_description_text_standart
                  : activeRoom === "Guernica"
                  ? jsonData?.rooms_description_text_guernica
                  : null}
              </p>
            </div>
            <div className="sidebox-container">
              <div className="sidebox">
                <h2>{jsonData?.rooms_services_title}</h2>
                <div className="flex_center_space-between">
                  <img src="/assets/wifi-icon.png" alt="wifi icon" />
                  <p>{jsonData?.home_servicies_first}</p>
                </div>
                <div className="flex_center_space-between ">
                  <img src="/assets/buffet-icon.png" alt="Buffet icon" />
                  <p>{jsonData?.home_servicies_second}</p>
                </div>
                <div className="flex_center_space-between ">
                  <img src="/assets/tv-icon.svg" alt="TV icon" />
                  <p>{jsonData?.home_servicies_third}</p>
                </div>
                <div className="flex_center_space-between ">
                  <img
                    src="/assets/air-conditioning-icon.svg"
                    alt="Air-conditioning icon"
                  />
                  <p>{jsonData?.home_servicies_fith}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <GetInTouch jsonData={jsonData} />
        <div className="container-with-title rooms-recomendation">
          <div className="header">
            <div className="img-container">
              <img src="/assets/buffet-icon.png" alt="Information icon" />
              <h2>{jsonData?.rooms_recomendations_title}</h2>
            </div>
          </div>
          <div className="box container-with-title-content">
            <div
              className={`rooom-card ${
                activeRoom === "Standard" ? "hiden" : null
              }`}
            >
              <img src="/assets/room standart.jpg" alt="" />
              <div className="room-content">
                <h3>
                  {language === "por" ? "Quarto " : "Habitación "}Standard
                </h3>
                <p className="subtitle">
                  {language === "por" ? "Simples e duplo " : "Simple y doble"}
                </p>
                <p>
                  {language === "por"
                    ? "Conforto acessível para viajantes individuais."
                    : "Comodidad asequible para viajeros individuales."}
                </p>
                <div className="icon-container">
                  <img src="/assets/wifi-icon.png" alt="Information icon" />
                  <img src="/assets/buffet-icon.png" alt="Information icon" />
                  <img src="/assets/tv-icon.svg" alt="Information icon" />
                  <img
                    src="/assets/air-conditioning-icon.svg"
                    alt="Information icon"
                  />
                </div>
                <hr></hr>
                <Link
                  to="/rooms/Standard"
                  onClick={() => {
                    window.scroll(0, 0);
                  }}
                  className="theme-link"
                >
                  {language === "por" ? "Ver mais " : "Ver más"}
                </Link>
              </div>
            </div>
            <div
              className={`rooom-card ${
                activeRoom === "Ejecutiva" ? "hiden" : null
              }`}
            >
              <img src="/assets/gallery-room-Ejecutiva-02.jpg" alt="" />
              <div className="room-content">
                <h3>
                  {language === "por" ? "Quarto " : "Habitación "}Ejecutiva
                </h3>
                <p className="subtitle">
                  {language === "por" ? "Simples e duplo " : "Simple y doble"}
                </p>
                <p>
                  {language === "por"
                    ? "Modernas, elegantes e com comodidades completas."
                    : "Modernas, elegantes y con comodidades completas."}
                </p>
                <div className="icon-container">
                  <img src="/assets/wifi-icon.png" alt="Information icon" />
                  <img src="/assets/buffet-icon.png" alt="Information icon" />
                  <img src="/assets/tv-icon.svg" alt="Information icon" />
                  <img
                    src="/assets/air-conditioning-icon.svg"
                    alt="Information icon"
                  />
                </div>
                <hr></hr>
                <Link
                  to="/rooms/Ejecutiva"
                  onClick={() => {
                    window.scroll(0, 0);
                  }}
                  className="theme-link"
                >
                  {language === "por" ? "Ver mais " : "Ver más"}
                </Link>
              </div>
            </div>
            <div
              className={`rooom-card ${
                activeRoom === "Guernica" ? "hiden" : null
              }`}
            >
              <img src="/assets/room Guernica.jpg" alt="" />
              <div className="room-content">
                <h3>Suite Guernica</h3>
                <p className="subtitle">
                  {language === "por" ? "Simples e duplo " : "Simple y doble"}
                </p>
                <p>
                  {language === "por"
                    ? " Elegante espaço de luxo e conforto inesquecíveis."
                    : "Elegante espacio de lujo y comodidad inolvidables."}
                </p>
                <div className="icon-container">
                  <img src="/assets/wifi-icon.png" alt="Information icon" />
                  <img src="/assets/buffet-icon.png" alt="Information icon" />
                  <img src="/assets/tv-icon.svg" alt="Information icon" />
                  <img
                    src="/assets/air-conditioning-icon.svg"
                    alt="Information icon"
                  />
                </div>
                <hr></hr>
                <Link
                  to="/rooms/Guernica"
                  onClick={() => {
                    window.scroll(0, 0);
                  }}
                  className="theme-link"
                >
                  {language === "por" ? "Ver mais " : "Ver más"}
                </Link>
              </div>
            </div>
            <div
              className={`rooom-card ${
                activeRoom === "Donostia" ? "hiden" : null
              }`}
            >
              <img src="/assets/donostia_01.jpg" alt="" />
              <div className="room-content">
                <h3>Suite Donostia</h3>
                <p className="subtitle">
                  {language === "por" ? "Simples e duplo " : "Simple y doble"}
                </p>
                <p>
                  {language === "por"
                    ? "Aconchegante e espaçosa com comodidades para desfrutar."
                    : "Acogedora y espaciosa con comodidades para disfrutar."}
                </p>
                <div className="icon-container">
                  <img src="/assets/wifi-icon.png" alt="Information icon" />
                  <img src="/assets/buffet-icon.png" alt="Information icon" />
                  <img src="/assets/tv-icon.svg" alt="Information icon" />
                  <img
                    src="/assets/air-conditioning-icon.svg"
                    alt="Information icon"
                  />
                </div>
                <hr></hr>
                <Link
                  to="/rooms/Donostia"
                  onClick={() => {
                    window.scroll(0, 0);
                  }}
                  className="theme-link"
                >
                  {language === "por" ? "Ver mais " : "Ver más"}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
}

export default Rooms;
