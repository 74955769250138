import React from "react";
import { Container } from "./styled";

const Footer = () => {
  return (
    <Container>
      <p>© Copyright 2024 | Hotel Euskadi</p>
      <div className="flex_center_space-between footer-list_containers">
        <div className="footer-info-container">
          <ul className="footer-list">
            <li>
              <a
                href="https://www.facebook.com/hoteleuskadi/"
                target="_blank"
                className="twitter_link"
                rel="noreferrer"
              >
                <img src="/assets/logo-facebook.png" alt="logo facebook" />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/hoteleuskadi"
                target="_blank"
                className="twitter_link"
                rel="noreferrer"
              >
                <img src="/assets/logo-instagram.png" alt="logo instagram" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </Container>
  );
};

export default Footer;
