import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Home, Contact, About, Rooms, ErrorPage } from "./containers";
import { Loader } from "./components";
import React, { useState, useEffect } from "react";
function App() {
  const [language, setLanguage] = useState("esp");
  const [jsonData, setJsonData] = useState(null);

  useEffect(() => {
    const fetchJsonData = async () => {
      if (language === "por") {
        const response = await import("./textContent/pt.json");
        const data = await response.default;
        setJsonData(data);
      } else if (language === "esp") {
        const response = await import("./textContent/esp.json");
        const data = await response.default;
        setJsonData(data);
      }
    };
    fetchJsonData();
  }, [language]);

  if (jsonData === null) return <Loader />;

  return (
    <Router>
      <Switch>
        <Route
          exact
          path="/"
          render={(props) => (
            <Home
              {...props}
              setLanguage={setLanguage}
              language={language}
              jsonData={jsonData}
            />
          )}
        />
        <Route
          path="/rooms"
          render={(props) => (
            <Rooms
              {...props}
              setLanguage={setLanguage}
              language={language}
              jsonData={jsonData}
            />
          )}
        />
        <Route
          path="/about"
          render={(props) => (
            <About
              {...props}
              setLanguage={setLanguage}
              language={language}
              jsonData={jsonData}
            />
          )}
        />
        <Route
          path="/contact"
          render={(props) => (
            <Contact
              {...props}
              setLanguage={setLanguage}
              language={language}
              jsonData={jsonData}
            />
          )}
        />
        <Route
          path="/*"
          render={(props) => (
            <ErrorPage
              {...props}
              setLanguage={setLanguage}
              language={language}
              jsonData={jsonData}
            />
          )}
        />
      </Switch>
    </Router>
  );
}

export default App;
